<template>
    <div class="dashboard">
        <router-link to="/menu/statistics/" class="dashboard__link">
            <img src="../../assets/images/statistics_active.svg" alt="" class="dashboard__image">
            <span class="dashboard__text">Статистика</span>
        </router-link>
    </div>
</template>

<script>
export default {
}
</script>

<style lang="less">
.dashboard {
    display: flex;

    &__link {
        flex: 1;
        padding: 24px;
        display: flex;
        flex-direction: column;
        border-radius: 12px;
        border: 1px solid #E2E9F1;

        &:first-child {
            margin-right: 24px;
        }
    }

    &__image {
        width: 40px;
        margin-bottom: 48px;
    }

    &__text {
        color: #2563EB;
        font-size: 18px;
        font-style: normal;
        font-weight: 800;
        line-height: 20px;
    }
}
</style>