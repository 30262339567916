<template>
    <section class="statistics">
        <header class="statistics__header">
            <h2 class="statistics__title">Статистика</h2>
            <!-- <div class="statistics__filter">
                <date-pick v-model="from" type="text" class="statistics__input" :weekdays="lang.weekdays"
                    :months="lang.months"></date-pick>
                &nbsp;-&nbsp;
                <date-pick v-model="to" type="text" class="statistics__input" :weekdays="lang.weekdays"
                    :months="lang.months"></date-pick>
                <button class="statistics__button button" @click="dataStatistics(from, to)">Применить</button>
            </div> -->
        </header>
        <div v-if="statistics" class="statistics__content">
            <ul v-for="item in list" :key="item.name" class="statistics__list">
                <li class="statistics__item">{{ item.name }}</li>
                <li class="statistics__item">{{ item.value }}</li>
            </ul>
        </div>
    </section>
</template>

<script>
// import DatePick from 'vue-date-pick';
import 'vue-date-pick/dist/vueDatePick.css';
import { getStatistics } from '@/api';

export default {
    // components: {
    //     DatePick
    // },
    data() {
        return {
            from: '',
            to: '',
            lang: {
                months: [
                    'Январь',
                    'Февраль',
                    'Март',
                    'Апрель',
                    'Май',
                    'Июнь',
                    'Июль',
                    'Август',
                    'Сентябрь',
                    'Октябрь',
                    'Ноябрь',
                    'Декабрь'
                ],
                weekdays: [
                    'Вс',
                    'Пн',
                    'Вт',
                    'Ср',
                    'Чт',
                    'Пт',
                    'Сб'
                ],
            },
            statistics: null
        }
    },
    computed: {
        list() {
            return [{
                name: 'Заголовок',
                value: 'Значение'
            },
            {
                name: 'Количество пользователей',
                value: this.statistics?.totalUsers || 0
            },
            {
                name: 'Количество нажатий на поддержите меня',
                value: this.statistics?.supportButtonClicks || 0
            },
            {
                name: 'Количество нажатий на читать книгу',
                value: this.statistics?.readButtonClicks || 0
            }]
        }
    },
    async created() {
        await this.dataStatistics();
    },
    methods: {
        async dataStatistics(start, end) {
            if (start && end) {
                this.statistics = await getStatistics(start, end);
                return;
            }
            this.statistics = await getStatistics();
        },
    }
}
</script>

<style lang="less">
.statistics {
    &__header {
        display: flex;
        align-items: center;
        justify-content: space-between;
    }

    &__title {
        font-size: 28px;
        font-weight: 800;
        line-height: 20px;
    }

    &__filter {}

    &__input {
        padding: 8px 14px;
        width: 128px;
        border-radius: 8px;
        border: 1px solid #D0D5DD;
        color: #101828;
        font-size: 16px;
        font-style: normal;
        font-weight: 400;
        line-height: 24px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

        &.vdpWithInput>input {
            width: 100%;
            padding: 0;
            font-size: inherit;
        }

        .vdpClearInput {
            display: none;
        }

        .vdpInnerWrap {
            min-width: 260px;
        }

        .vdpPeriodControl>button {
            font-size: 20px;
            padding: 10px 6px;
            color: #475366;
        }

        .vdpHeadCellContent {
            font-size: 12px;
        }

        .vdpArrowNext:after {
            border-left-color: #2563EB;
        }

        .vdpArrowPrev:after {
            border-right-color: #2563EB;
        }

        .vdpCellContent {
            font-size: 14px;
        }

        .vdpCell.selectable:hover .vdpCellContent {
            background: #D7DDFE;
        }

        .vdpCell.selected .vdpCellContent {
            background: #2563EB;
        }

    }

    &__button {
        padding: 10px 14px;
        margin-left: 24px;
        font-size: 14px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
        border-radius: 8px;
        box-shadow: 0px 1px 2px 0px rgba(16, 24, 40, 0.05);

    }

    &__content {
        margin-top: 24px;
        border-radius: 6px;
        border: 0.5px solid var(--color-split, rgba(0, 0, 0, 0.06));
        overflow: hidden;
    }

    &__list {
        display: flex;
        align-items: center;
        width: 100%;

        &:first-child {
            .statistics__item {
                background: rgba(71, 83, 102, 0.02);
                color: #475366;
                font-weight: 600;
            }
        }
    }

    &__item {
        padding: 8px;
        flex: 1 1 50%;
        border: 0.5px solid rgba(0, 0, 0, 0.06);
        color: rgba(0, 0, 0, 0.88);
        font-size: 14px;
        font-style: normal;
        font-weight: 400;
        line-height: 22px;

    }
}
</style>