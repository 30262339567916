import axios from "axios";
import router from "@/router";

const api = axios.create({
    baseURL: process.env.VUE_APP_API_URL,
    headers: {
        'Authorization': `Bearer ${localStorage.getItem('token')}`
    }
});

api.interceptors.response.use(
    response => {
      return response;
    },
    error => {
      if (error.response && error.response.status === 401) {
        localStorage.removeItem('token');
        if (router.currentRoute.name !== 'login') {
            router.push({name: 'login'});
        }
      }
      return Promise.reject(error);
    }
);

export async function login(user, password) {
    try {
        const {data} = await api.post('/sign-in', {
            username: user,
            password: password
        })
        localStorage.setItem('token', data.token);

        api.defaults.headers['Authorization'] = `Bearer ${data.token}`;
        router.push('/menu/dashboard/');

        return data;
    } catch {
        return false;
    }
}

export async function getStatistics(start, end) {
    try {
        const {data} = await api.get('/statistics', {
            params: {
                startDate: start,
                endDate: end
            }
        })
        return data;
    } catch {
        return false;
    }
}

export async function getSettings() {
    try {
        const {data} = await api.get('/settings');
        return data;
    } catch {
        return false;
    }
}


export async function sendSettings(promocode, link) {
    try {
        const {data} = await api.post('/settings', {
            promoCode: promocode,
            promoCodeLink: link
        })

        return data;
    } catch {
        return false;
    }
}